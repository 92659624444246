import router from '@/router';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import Cookies from 'js-cookie';
import config from '@/config';
import esApiV3 from './esapiv3.service';
import exception from './exception.service';
import analyticsService from './analytics.service';

const api = {
  getUserInfo: () => 'user-profiles/self',
  userProfiles: () => 'user-profiles',
  userProfileById: (id) => `user-profiles/${id}`,
  restoreUser: (id) => `user-profiles/${id}/restore`,
  locationContacts: (locationId) => `user-profiles/contacts/${locationId}`,
  searchUsers: () => 'user-profiles/search',
  availability: () => 'user-profiles/availability',
};

const impersonateUserIdKey = 'impersonateUserId';

export default {
  getCookieDomain() {
    return config.mode === 'local' ? '' : '.86repairs.com';
  },
  isImpersonatingUser() {
    return !!this.getImpersonatedUserId();
  },
  getImpersonatedUserId() {
    return Cookies.get(impersonateUserIdKey);
  },
  setImpersonatedUserId(userId) {
    Cookies.set(impersonateUserIdKey, userId, {
      domain: this.getCookieDomain(),
    });
    window.location.assign('/');
  },
  clearImpersonatedUserId(reload = true) {
    Cookies.set(impersonateUserIdKey, '', {
      domain: this.getCookieDomain(),
    });
    if (reload) {
      window.location.assign('/');
    }
  },
  async getUserInfo() {
    try {
      const { data } = await esApiV3.get(api.getUserInfo());
      analyticsService.setUserId(data.id);
      analyticsService.setDisabled(
        data.isBohUser || this.isImpersonatingUser(),
      );
      Sentry.setUser({
        id: data.id,
        name: data.name,
      });
      return data;
    } catch (ex) {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get user info.',
      );
    }
  },
  async handleSuccessfulLogin() {
    const user = await this.getUserInfo();
    analyticsService.logEvent('Login');
    const hasLinkedSocials =
      user.identities != null &&
      !Object.values(user.identities).every((i) => i == null);
    const isPartsTown = store.getters['auth/isPartsTown'];

    const params = new URLSearchParams(window.location.search);
    if (params.has('nextRoute')) {
      const path = params.get('nextRoute').replace(window.location.origin, '');
      await router.push({ path });
    } else if (hasLinkedSocials || isPartsTown) {
      await router.push({ name: 'Home' });
    } else {
      await router.push({ name: 'LinkAccounts' });
    }
  },
  async getPeople(query) {
    try {
      const { data } = await esApiV3.get(api.userProfiles(), { params: query });
      return data;
    } catch (ex) {
      console.warn('Error getting people', ex);
      return null;
    }
  },
  async createContact(body) {
    await esApiV3.post(api.userProfiles(), body);
  },
  async patchPerson(personId, personData) {
    await esApiV3.patch(api.userProfileById(personId), personData);
  },
  async deleteUser(id) {
    try {
      await esApiV3.delete(api.userProfileById(id));
    } catch (error) {
      exception.showCustomError(
        error,
        'An error occurred while trying to archive user',
      );
    }
  },
  async restoreUser(id) {
    try {
      const res = await esApiV3.post(api.restoreUser(id));
      return res.data;
    } catch (error) {
      exception.showCustomError(
        error,
        'An error occurred while trying to restore user',
      );
    }
  },
  async getLocationContacts(locationId) {
    try {
      const res = await esApiV3.get(api.locationContacts(locationId));
      return res.data;
    } catch (ex) {
      exception.showCustomError(
        ex,
        `An error occurred while trying to get contacts for location: ${locationId}`,
      );
    }
  },
  async searchUsers(searchText) {
    try {
      const res = await esApiV3.get(api.searchUsers(), {
        params: {
          search: searchText,
        },
      });
      return res.data;
    } catch (ex) {
      return [];
    }
  },
  async searchForDupes(query) {
    try {
      const res = await esApiV3.get(api.availability(), {
        params: query,
      });
      return res.data;
    } catch (ex) {
      exception.showCustomError(
        ex,
        'An error occurred while trying to validate info',
      );
    }
  },
};
