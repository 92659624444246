import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Pagination } from '@/service/pagination.service';
import {
  getAssetDetailById,
  getAssetPickerView,
  getAssetTypes,
} from '@/service/asset.service';

const DEFAULT_FILTERS = {
  percentOfSpendStart: null,
  percentOfSpendEnd: null,
  serviceCategoryIds: null,
  serviceCategoryId: null,
  locations: [],
  warrantyEligible: null,
  filterText: null,
};

export const useAssetStore = defineStore('assets', {
  state: () => ({
    filter: cloneDeep(DEFAULT_FILTERS),
    pagination: null,
    asset: {},
    types: [],
    typesLoading: false,
  }),
  actions: {
    setFilter(filter) {
      if (this.pagination) this.pagination.setQuery(filter);
      this.filter = filter;
    },
    clearFilter() {
      const filter = cloneDeep(DEFAULT_FILTERS);
      this.setFilter(filter);
    },
    initializePagination(filter) {
      this.pagination = new Pagination(filter || {}, 50, getAssetPickerView);
    },
    getPaginationItems() {
      this.pagination.getItems();
    },
    setLocationFilter(locations) {
      this.setFilter({ locations });
      this.initializePagination();
    },
    async getAssetDetail(id) {
      const asset = await getAssetDetailById(id);
      this.asset = asset;
    },
    async getAssetTypes() {
      if (this.types.length === 0 && !this.typesLoading) {
        this.typesLoading = true;
        const results = await getAssetTypes();
        this.types = results;
        this.typesLoading = false;
      }
    },
    clearAsset() {
      this.asset = {};
    },
  },
  getters: {
    items: (state) => state.pagination?.items || [],
    totalItems: (state) => state.pagination?.count || 0,
    loading: (state) => state.pagination?.loading || false,
  },
});
